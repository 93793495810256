import React from "react";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { NavBar } from "./components/NavBar";
import { Banner } from "./components/Banner";
import { Skills } from "./components/Skills";
import { Projects } from "./components/Projects";
import { Contact } from "./components/Contact";
import { Footer } from "./components/Footer";
import styled from "styled-components";
import logo from "./assets/blackMoonImages/blackLogo/SVG Vector Files/Transparent Logo.svg";

const ComingSoonText = styled.h1`
  font-size: 20px; // This is the desktop size
  align-items: center;
  justify-content: center;
  display: flex;
  margin: auto;
  //Align middle of page
  @media (max-width: 768px) {
    font-size: 10px; // This is the mobile size
  }
`;

const Logo = styled.div`
  align-items: center;
  justify-content: center;
  display: flex;
  margin: auto;
  img {
    width: 100%;
    height: auto;
  }
}`;

function App() {
  return (
    // <div className="App">
    //   <NavBar />
    //   <Banner />
    //   {/* <Skills />
    //   <Projects />
    //   <Contact />
    //   <Footer /> */}
    // </div>
    <>
      <Logo>
        <img src={logo} alt="Logo" />
      </Logo>
      <ComingSoonText>Coming Soon!!!</ComingSoonText>
    </>
  );
}

export default App;
